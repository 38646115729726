import React, { Component } from "react"
import { Link } from "gatsby"
import "../css/overlay.css"
import "../css/hamburger.scss"

const linkActiveStyle = {
  color: "#cecdcd",
}

const linkStyle = {
  textDecoration: "none",
}

const links = [
  { name: "HOME", url: "/" },
  { name: "ABOUT", url: "/about" },
  { name: "GET LOVE NOTES", url: "/get-love" },
  { name: "GIVE LOVE NOTES", url: "/give-love" },
]

export default class Menu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false,
    }
  }

  toggleMenu = () => {
    this.setState(state => ({ menuOpen: !state.menuOpen }))
  }

  render() {
    const { menuOpen } = this.state
    return (
      <div>
        <button
          className={`hamburger hamburger--spring ${menuOpen && "is-active"}`}
          onClick={this.toggleMenu}
          type="button"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>

        <div className={`overlay overlay-hugeinc ${menuOpen && "open"}`}>
          <nav className="brandon-bold flex flex-column justify-center">
            {links.map(link => (
              <Link
                className="f4 mb4 tracked"
                key={link.url}
                activeStyle={linkActiveStyle}
                style={linkStyle}
                to={link.url}
              >
                {link.name}
              </Link>
            ))}
          </nav>
          <div className="white flex f3 justify-center">
            <a
              href="https://www.facebook.com/empowerandrisedaily/"
              className="link white outline-0"
            >
              <div style={{ width: '24px', height: '24px'}} className="ma2">
                <i className="fab fa-facebook" />
              </div>
            </a>
            <a
              href="https://www.instagram.com/empowerandrisedaily/"
              className="link white outline-0"
            >
              <div style={{ width: '24px', height: '24px'}} className="ma2">
                <i className="fab fa-instagram" />
              </div>
            </a>
          </div>
        </div>
      </div>
    )
  }
}
