import React from 'react';

const EMLogo = (props) => {
  return ( 
    <svg {...props} viewBox="0 0 272.07 170.44">
      <path d="M61.54,136.85c2.42,4.48,4.79,8.88,7.17,13.26,1.34,2.46,2.71,4.89,4.06,7.34.93,1.68.36,3-1.57,3.32-2.69.39-5.4.62-8.1.88-2.11.2-4.22.33-6.33.52a1.85,1.85,0,0,1-2.07-1.22c-3.16-6.94-6.37-13.85-9.51-20.8-.37-.81-.73-1.16-1.67-1.07-1.9.18-3.82.22-5.81.32-.67,3.65-1.34,7.26-2,10.86s-1.32,7.31-2,11a2.33,2.33,0,0,1-2.57,2.16q-5.87,0-11.75,0c-1.49,0-2-.68-1.71-2.14q3.79-17.81,7.61-35.61,2.2-10.31,4.42-20.61a2.28,2.28,0,0,1,2.44-2.12c9.63-.84,19.27-1.6,28.88-2.59,7.94-.82,15.59,3.9,17.46,11.14,1.14,4.42.17,8.58-1.95,12.48-3.2,5.89-8.15,9.85-14.17,12.58Zm-21.82-8.52L54,127.18a10.3,10.3,0,0,0,8.36-5.45,5.92,5.92,0,0,0,.86-4.25c-.55-2.76-3.15-4.45-6.38-4.15l-9.54.89c-1.7.16-3.4.29-5.1.43Z" />
      <path d="M219.16,82.35l1.71,12.23c2.73-.22,5.35-.48,8-.64,4.31-.27,8.61-.49,12.92-.7A2.08,2.08,0,0,1,244,95.08q1,5.42,1.91,10.85c.25,1.54-.32,2.1-2.06,2.16-3.07.11-6.15.15-9.22.31-3.86.2-7.72.49-11.76.75l1.87,13.38c3.39-.2,6.66-.46,9.92-.55,5.59-.16,11.18-.23,16.78-.3a2.28,2.28,0,0,1,2.37,2c.68,3.57,1.33,7.15,1.94,10.73.28,1.64-.24,2.16-1.94,2.19-7.91.16-15.82.22-23.72.51-5.47.2-10.92.73-16.38,1.12-1.94.14-2.52-.33-2.75-2.27q-2-17-3.93-34Q205.33,87,203.57,72.12c-.19-1.64.32-2.37,2-2.64q15.93-2.52,32-3.58c1.24-.08,2.47-.14,3.71-.23a2,2,0,0,1,2.27,1.67q1.13,5.7,2.11,11.42c.18,1.06-.47,1.71-1.59,1.78-6.22.4-12.44.78-18.66,1.2C223.4,81.9,221.38,82.14,219.16,82.35Z" />
      <path d="M166.73,77.18a28.66,28.66,0,0,1,12.93,3.29,2.24,2.24,0,0,1,1.23,3.16c-1.37,3-2.78,5.93-4.33,8.81-.85,1.59-2.08,1.83-3.73,1.07-3.33-1.52-6.73-2.78-10.47-2.64a7.66,7.66,0,0,0-6.42,3.39,4.93,4.93,0,0,0,1.33,7.15,18.79,18.79,0,0,0,7.37,2.69,38.18,38.18,0,0,1,11.7,4.08c11.47,6.54,11.43,19,5.9,26.83-4.43,6.25-10.65,9.79-18.06,11.25-5.74,1.13-11.53,1.32-17.19-.49a30.82,30.82,0,0,1-5.4-2.52c-1.26-.69-1.33-1.3-.67-2.59q2.17-4.21,4.36-8.4a2.76,2.76,0,0,1,3.92-1.36c3.93,1.67,8,2.87,12.31,2.48a9.29,9.29,0,0,0,4.78-1.73,6.14,6.14,0,0,0,0-10.26c-2.39-1.75-5.21-2.36-8-2.92a29.56,29.56,0,0,1-10.71-4.3c-6-4-8.37-9.1-6.67-16.15,2.46-10.2,8.73-17.07,19-19.92A48.43,48.43,0,0,1,166.73,77.18Z" />
      <path d="M118.42,91.71q-.79,20.17-1.58,40.34-.38,9.88-.75,19.75c-.07,1.71-.69,2.49-2.36,2.8q-5.72,1-11.45,2c-1.27.21-1.94-.48-1.85-1.8q1.46-22,2.93-44,.52-7.89,1-15.78a2.67,2.67,0,0,1,2.36-2.85l9.56-2.23C118,89.54,118.5,90,118.42,91.71Z" />
      <path d="M60.95,13.61a.46.46,0,0,1,.46-.46h9.94a.46.46,0,0,1,.46.46v2.62a.46.46,0,0,1-.46.46H64.69v3h5.47a.47.47,0,0,1,.46.46v2.62a.46.46,0,0,1-.46.46H64.69v3.24h6.65a.46.46,0,0,1,.46.46v2.62a.46.46,0,0,1-.46.46H61.41a.46.46,0,0,1-.46-.46Z" />
      <path d="M80.75,13.29a.46.46,0,0,1,.43-.38h.38a.53.53,0,0,1,.41.24l5.57,10.37h0l5.57-10.37a.53.53,0,0,1,.41-.24H94a.46.46,0,0,1,.43.38l2.71,16.13a.44.44,0,0,1-.46.53H93.81a.49.49,0,0,1-.46-.38l-1.08-7.42h0l-4,7.78a.56.56,0,0,1-.41.26h-.43a.52.52,0,0,1-.41-.26l-4.06-7.78h0l-1.06,7.42a.47.47,0,0,1-.46.38H78.49a.44.44,0,0,1-.46-.53Z" />
      <path d="M104.25,13.61a.46.46,0,0,1,.46-.46h5.78a5.35,5.35,0,1,1,0,10.7H108v5.64a.47.47,0,0,1-.46.46H104.7a.46.46,0,0,1-.46-.46Zm6,6.74a1.88,1.88,0,0,0,1.9-1.9,1.83,1.83,0,0,0-1.9-1.78H108v3.67Z" />
      <path d="M129.85,12.91a8.64,8.64,0,1,1-8.62,8.66A8.62,8.62,0,0,1,129.85,12.91Zm0,13.44a4.8,4.8,0,1,0-4.78-4.78A4.81,4.81,0,0,0,129.85,26.35Z" />
      <path d="M143.87,13.73a.42.42,0,0,1,.43-.58h2.81a.49.49,0,0,1,.43.34l2.06,8.14h.1l3.72-8.45a.46.46,0,0,1,.41-.26h.43a.41.41,0,0,1,.41.26l3.72,8.45h.1l2.06-8.14a.49.49,0,0,1,.43-.34h2.81a.42.42,0,0,1,.43.58l-4.54,16.13a.46.46,0,0,1-.43.34h-.38a.49.49,0,0,1-.41-.26l-4.39-9.82H154l-4.37,9.82a.49.49,0,0,1-.41.26h-.38a.46.46,0,0,1-.43-.34Z" />
      <path d="M170.87,13.61a.46.46,0,0,1,.46-.46h9.94a.46.46,0,0,1,.46.46v2.62a.46.46,0,0,1-.46.46h-6.65v3h5.47a.47.47,0,0,1,.46.46v2.62a.46.46,0,0,1-.46.46h-5.47v3.24h6.65a.46.46,0,0,1,.46.46v2.62a.46.46,0,0,1-.46.46h-9.94a.46.46,0,0,1-.46-.46Z" />
      <path d="M189,13.61a.46.46,0,0,1,.46-.46h7.18a5.22,5.22,0,0,1,5.23,5.18,5.27,5.27,0,0,1-3.55,4.82l3.29,6.1a.45.45,0,0,1-.41.7H198a.44.44,0,0,1-.38-.22l-3.19-6.36h-1.66v6.12a.47.47,0,0,1-.46.46h-2.86a.46.46,0,0,1-.46-.46Zm7.32,6.72a1.92,1.92,0,0,0,1.82-1.92,1.84,1.84,0,0,0-1.82-1.82h-3.55v3.74Z" />
      <path d="M128.9,55.91h-3.5a.47.47,0,0,1-.46-.46V53.77a.47.47,0,0,1,.46-.46h3.5V49.79a.45.45,0,0,1,.46-.43H131a.45.45,0,0,1,.46.43v3.53H135a.45.45,0,0,1,.43.46v1.68a.45.45,0,0,1-.43.46h-3.53v3.5a.45.45,0,0,1-.46.43h-1.68a.45.45,0,0,1-.46-.43Z" />
    </svg>
   );
}
 
export default EMLogo;