import React from "react"
import PropTypes from "prop-types"
import { withPrefix, StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import shareImage from "../images/shareimage.jpg"

import Menu from "./menu"
import Footer from "./footer"

const SimpleFooter = () => (
  <footer className="pv4 white bg-dusty-rose brandon-light tc">
    <div className="tc mv3 center flex flex-column flex-row-ns items-center justify-center">
      <span className="savoy-reg f4 mb2 mb0-ns">Brought to You by</span>
      <span className="mh2 brandon-light f5 tracked lh-copy">
        <a className="link white dim" href="https://threeboudoir.com/">
          THREE BOUDOIR
        </a>
        <span className="dn dib-ns">/</span> <br className="dn-ns" />{" "}
        Photography Experiences for Women
      </span>
    </div>
    <div className="white flex f3 justify-center">
      <a
        href="https://www.facebook.com/empowerandrisedaily/"
        className="link white outline-0 dim"
      >
        <i className="fab fa-facebook pa2" />
      </a>
      <a
        href="https://www.instagram.com/empowerandrisedaily/"
        className="link white outline-0 dim"
      >
        <i className="fab fa-instagram pa2" />
      </a>
    </div>

    <a
      href="https://threeboudoir.com/privacy/"
      className="link white outline-0 dim"
    >
      <div className="flex mb4  f6 fw1 items-end w-100 justify-center silver pv2 brandon-light">
        Privacy Policy + Terms of Use
      </div>
    </a>
  </footer>
)

const Layout = ({ children, simpleFooter, title }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <Helmet>
            <title>{title}</title>
            <link
              rel="stylesheet"
              href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
              integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf"
              crossorigin="anonymous"
            />
            <meta property="og:url" content="https://empowerandrise.com/" />
            <meta property="og:image" content={shareImage} />
            <script src={withPrefix('fb.js')} type="text/javascript" />
            <noscript>
              {
                `
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=1636576956596603&ev=PageView&noscript=1"
      />
        `
              }
            </noscript>
            <noscript>
              {
                `
          <img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=224059258529997&ev=PageView&noscript=1"
        />
          `
              }
            </noscript>
          </Helmet>
          <Menu />
          <main className="savoy-reg">{children}</main>
          {simpleFooter ? <SimpleFooter /> : <Footer />}
        </>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
